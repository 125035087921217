<template>
  <div>
    <img style="width: 100%" alt="" src="@/assets/images/patientInfo.png" />
    <div class="ml-10 mr-10">
      <minInfo :patientModel="patientModel" v-show="patientModel.Next != true" />
      <medicalHistory :patientModel="patientModel" v-show="patientModel.Next == true" />
    </div>
    <div class="w-full">
      <div class="justify-right">
        <vs-button
          v-show="patientModel.Next == true"
          @click="Submit()"
          color="rgb(62, 201, 214)  "
          type="filled"
          style="float: right"
          >{{ $t("Submit") }}</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
// import point from "../../components/point.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import minInfo from "./minInfo.vue";
import medicalHistory from "./medicalHistory.vue";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";

export default {
  components: {
    // point
    minInfo,
    medicalHistory,
  },
  data() {
    return {
      patientModel: {
        PatientMedicalHistory: {
          PatientMedicalHistoryPastSurgeries:[],
          PatientMedicalHistoryMedications:[]
        },
        Next: true,
        BirthDate: new Date(),
      },
      options: [],
      radios1: "luis",
    };
  },

  methods: {
    Submit() {
      this.$vs.loading();

      this.$store
        .dispatch("patientList/UpdatePatient", this.patientModel)
        .then((res) => {
          console.error(res);
          window.showSuccess("successfully updated! please login");
          this.$vs.loading.close();
          this.$router.push({ name: "home" });

          // this.initPatientModelValues();
        })
        .catch((err) => {
          window.showError(err.response.data.message);
          this.$vs.loading.close();
          console.error(err);
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.patientModel.ProfileImagePath = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    },
  },
  computed: {
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule("InsuranceCompanyList", moduleInsuranceCompany);
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }

    this.$store.dispatch("CountryList/GetAllCountries");
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      debugger;

      this.$store.dispatch("patientList/GetPatientForMainData", ID).then((res) => {
        this.patientModel = res.data.Data;
        this.$set(this.patientModel, "Next", false);
        debugger;
        if (
          this.patientModel.PatientMedicalHistory == null ||
          this.patientModel.PatientMedicalHistory == undefined
        ) {
          this.patientModel.PatientMedicalHistory = {
                      PatientMedicalHistoryPastSurgeries:[],
          PatientMedicalHistoryMedications:[]
          };
        } else {
          debugger;
          if(this.patientModel.PatientMedicalHistory.IsLowPressure==true){
            this.patientModel.PatientMedicalHistory.IsHavePresssure = null;
          }
          if (this.patientModel.NumOfChildren > 0) {
            this.patientModel.HaveChildren = true;
          }
        }
      });
    }
  },
};
</script>
<style>
.textfont {
  font-family: "futuraMedium";
}
</style>
