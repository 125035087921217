<template>
  <div class="w-full justify-center">
    <h2>{{ $t("Pleasecompleteyourregistrationtoaccessourservices") }}</h2>

    <div class="vx-row w-full con-img justify-center">
      <img
        v-if="patientModel.ProfileImagePath"
        :src="baseURL + patientModel.ProfileImagePath"
        alt="user-img"
        width="70"
        height="70"
        class="rounded-full shadow-md cursor-pointer block m-4"
      />

      <img
        v-else
        :src="baseURL + 'Uploads/docotrGeneral.jpg'"
        alt="user-img"
        width="70"
        height="70"
        class="rounded-full shadow-md cursor-pointer block m-4"
      />
      <div class="position: absolute py-12 w-1">
        <input
          type="file"
          class="hidden"
          ref="uploadImgInput"
          @change="updateCurrImg"
          accept="image/*"
          @on-success="successUpload"
        />

        <vs-avatar
          @click="$refs.uploadImgInput.click()"
          color="primary"
          icon-pack="feather"
          icon="icon-upload"
          class="m-5"
        />
      </div>
    </div>
    <div class="vx-row w-full justify-center mt-5">
      <vs-input
        data-vv-validate-on="blur"
        name="name"
        icon-pack="feather"
        label-placeholder="name"
        :placeholder="$t('Name')"
        v-model="patientModel.NameEN"
        :icon-after="true"
        class="m-3 mt-3"
      />

      <vs-input
        data-vv-validate-on="blur"
        name="FamilyName"
        class="m-3 mt-3"
        icon-no-border
        label-placeholder="FamilyName"
        :placeholder="$t('FamilyName')"
        v-model="patientModel.FamilyName"
        :icon-after="true"
      />
      <div div class="vx-row w-full justify-center">
        <p class="xl:w-5/12 sm:w-1/4 m-1 mt-1" style="color: #454a62; font-size: 15px">
          {{ $t("Country") }}
        </p>
      </div>
      <div div class="vx-row w-full justify-center">
        <!-- <p class="vs-input--label"> {{ $t('Country') }}</p> -->

        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="Country"
          class="xl:w-5/12 sm:w-1/4 m-1 mt-1"
          :options="countries"
          v-model="patientModel.CountryID"
          label-placeholder="FamilyName"
          :icon-after="true"
          :reduce="(ID) => ID.ID"
          @input="onSelectCountry()"
        />
      </div>
      <div div class="vx-row w-full justify-center">
        <p class="xl:w-5/12 sm:w-1/4 m-1 mt-1" style="color: #454a62; font-size: 15px">
          {{ $t("PhoneNumber") }}
        </p>
      </div>
      <div div class="vx-row w-full justify-center">
         <VuePhoneNumberInput :placeholder="$t('PhoneNumber')" :default-country-code="defaultPhoneData.countryCode" @update="acceptNumber" v-model="patientModel.Phone" />

        <!-- <vs-input
          data-vv-validate-on="blur"
          name="Phone"
          class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
          icon-no-border
          @keyup="checkDailCod()"
          v-model="patientModel.Phone"
          :icon-after="true"
        /> -->
      </div>
      <div div class="vx-row w-full justify-center">
        <p class="xl:w-5/12 sm:w-1/4 m-1 mt-1" style="color: #454a62; font-size: 15px">
          {{ $t("PreferredLanguage") }}
        </p>
      </div>
      <div div class="vx-row w-full justify-center">
        <!-- <p class="vs-input--label"> {{ $t('Country') }}</p> -->

        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          data-vv-validate-on="blur"
          label="Name"
          name="PreferredLanguage"
          class="xl:w-5/12 sm:w-1/4 m-3 mt-3"
          v-model="patientModel.PreferredLanguageID"
          :options="languages"
          label-placeholder="PreferredLanguage"
          :placeholder="$t('PreferredLanguage')"
          :icon-after="true"
          :reduce="(ID) => ID.ID"
        />
      </div>
      <div div class="vx-row w-full justify-center">
        <!-- <p class="vs-input--label"> {{ $t('Country') }}</p> -->
      </div>
      <div div class="vx-row w-full justify-center">
        <b class="w-1/1 text-left m-2">
          <u
            ><p>{{ $t("Gender") }}</p></u
          ></b
        >

        <vs-radio class="m-2" v-model="patientModel.GenderID" :vs-value="2">{{
          $t("male")
        }}</vs-radio>
        <vs-radio v-model="patientModel.GenderID" :vs-value="1">{{
          $t("female")
        }}</vs-radio>
      </div>
      <div class="w-full">
        <div class="justify-right">
          <vs-button
            @click="changeMode()"
            color="rgb(62, 201, 214)  "
            type="filled"
            style="float: right"
            >{{ $t("Next") }}</vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleLanguage from "@/store/settings/language/moduleLanguage.js";
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  components: {
    VuePhoneNumberInput
    // point
  },
  data() {
    return {
      defaultPhoneData:{
        countryCode : ""
      },
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      // patientModel:{},
      options: [],
      radios1: "luis",
    };
  },
  props: {
    patientModel: {
      type: Object,
      required: true,
    },
  },
  watch: {
    patientModel () {
      this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.patientModel.CountryID).IsoCode
    }
  },
  methods: {
     acceptNumber(value) {
      debugger
       this.patientModel.phonevalue = value.e164;
       this.patientModel.CountryID = this.countries.find((x) => x.IsoCode === value.countryCode).ID
    },
    changeMode() {
      debugger;
      this.patientModel.Next = true;
    },

    onSelectCountry() {
      // debugger;
      // this.patientModel.Phone =
      //   "+" + this.countries.find((x) => x.ID === this.patientModel.CountryID).DailCode;
        this.defaultPhoneData.countryCode = this.countries.find((x) => x.ID === this.patientModel.CountryID).IsoCode
    },
    checkDailCod() {
      debugger;
      var code = this.countries.find((x) => x.ID === this.patientModel.CountryID)
        .DailCode;
      if (this.patientModel.Phone.length <= code.length) {
        this.patientModel.Phone = "+" + code;
      }
    },

    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur",
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
          this.patientModel.ProfileImagePath = response.data;
        });
      }
    },
  },
  computed: {
    languages() {
      return this.$store.state.LanguageList.languages;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
  },
  created() {
    if (!moduleLanguage.isRegistered) {
      this.$store.registerModule("LanguageList", moduleLanguage);
      moduleLanguage.isRegistered = true;
    }
    this.$store.dispatch("LanguageList/GetAllLanguages");

this.onSelectCountry();
    //  axios.get('https://extreme-ip-lookup.com/json/')
    //   .then(response => {
    //      debugger;
    //   this.patientModel.NameEN = response.data.country;
    //    })
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
</style>
